import React, {useState, useEffect} from "react";

//plik css
import './login.css'

export default function Login({onEvent}){
    const closeWindowReaction = () => {
        onEvent("test")
    }

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [confirmForm, setConfirmForm] = useState(false)
    const [errorLogin, setErrorLogin] = useState(false)
    const [confirmInfo, setConfirmInfo] = useState(false)
    const [confirmInfoError, setConfirmInfoError] = useState(false)
    const token = sessionStorage.getItem('token')

    const [kids, setKids] = useState(false)
    const [single, setSingle] = useState(false)

    const [confirmYesWesele, setConfirmYesWesele] = useState(false)
    const [confirmYesWeseleAlone, setConfirmYesWeseleAlone] = useState(false)
    const [confirmNoWesele, setConfirmNoWesele] = useState(false)
    const [confirmYesPoprawiny, setConfirmYesPoprawiny] = useState(false)
    const [confirmYesPoprawinyAlone, setConfirmYesPoprawinyAlone] = useState(false)
    const [confirmNoPoprawiny, setConfirmNoPoprawiny] = useState(false)
    const [confirmYesDzieciWesele, setConfirmYesDzieciWesele] = useState(false)
    const [confirmNoDzieciWesele, setConfirmNoDzieciWesele] = useState(false)
    const [confirmYesDzieciPoprawiny, setConfirmYesDzieciPoprawiny] = useState(false)
    const [confirmNoDzieciPoprawiny, setConfirmNoDzieciPoprawiny] = useState(false)

    
    useEffect(()=>{
      if (token){
        setConfirmForm(true)
        downloadData()
        // eslint-disable-next-line
      }},[])
     

    const checkData = (data) => {
        if (data.kids){
            setKids(true)
        }
        if (data.single){
            setSingle(true)
        }
        if (data.confirmWedding){
            setConfirmYesWesele(true)
        }
        if (!data.confirmWedding && data.confirmWedding != null){
            setConfirmNoWesele(true)
        }
        if (data.confirmRevisons){
            setConfirmYesPoprawiny(true)
        }
        if (!data.confirmRevisons && data.confirmRevisons != null){
            setConfirmNoPoprawiny(true)
        }
        if (data.confirmKidsWedding){
            setConfirmYesDzieciWesele(true)
        }
        if (!data.confirmKidsWedding && data.confirmKidsWedding != null){
            setConfirmNoDzieciWesele(true)
        }
        if (data.confirmKidsRevisions){
            setConfirmYesDzieciPoprawiny(true)
        }
        if (!data.confirmKidsRevisions && data.confirmKidsRevisions != null){
            setConfirmNoDzieciPoprawiny(true)
        }
        if (data.confirmWeddingAlone){
            setConfirmYesWeseleAlone(true)
        }
        if (data.confirmRevisionsAlone){
            setConfirmYesPoprawinyAlone(true)
        }
    }

    const downloadData = () => {

        const tokenNumber = sessionStorage.getItem('token')

        fetch('https://weddingbackend.azurewebsites.net/Account', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${tokenNumber}`
            },
            })
            
            .then(res=>{
                if (res.ok){
                    return res.json()
                }
                else {
                    setErrorLogin(true)
                    
                }
            })
            .then(data=> {
                checkData(data)
            })
            .catch(error=> console.log(error))
    }

    const loginHandler = (e) => {
        e.preventDefault()
        sendRequest()
        }

    const sendRequest = ()=>{

        const data = {
            name: login,
            password: password,
        }
        fetch('https://weddingbackend.azurewebsites.net/Account/sign-in', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
            })
            
            .then(res=>{
                if (res.ok){
                    setErrorLogin(false)
                    setConfirmForm(true)
                    return res.json()
                }
                else {
                    setErrorLogin(true)
                    
                }
            })
            .then(data=> {
                checkData(data)
                const tokenNumber = data.token
                sessionStorage.setItem('token', tokenNumber)
            })
            .catch(error=> console.log(error))
            
            
    }
    const confirmTakWesele = ()=>{
        setConfirmYesWesele(true)
        setConfirmNoWesele(false)
        setConfirmYesWeseleAlone(false)
    }
    const confirmTakWeseleAlone = ()=> {
        setConfirmYesWesele(false)
        setConfirmYesWeseleAlone(true)
        setConfirmNoWesele(false)
    }
    const confirmNieWesele = ()=>{
        setConfirmNoWesele(true)
        setConfirmYesWesele(false)
        setConfirmYesWeseleAlone(false)
    }
    const confirmTakPoprawiny = ()=>{
        setConfirmYesPoprawiny(true)
        setConfirmYesPoprawinyAlone(false)
        setConfirmNoPoprawiny(false)
    }
    const confirmTakPoprawinyAlone = ()=>{
        setConfirmYesPoprawiny(false)
        setConfirmYesPoprawinyAlone(true)
        setConfirmNoPoprawiny(false)
    }
    const confirmNiePoprawiny = ()=>{
        setConfirmNoPoprawiny(true)
        setConfirmYesPoprawiny(false)
        setConfirmYesPoprawinyAlone(false)
    }
    const confirmTakDzieciWesele = ()=>{
        setConfirmYesDzieciWesele(true)
        setConfirmNoDzieciWesele(false)
    }
    const confirmNiekDzieciWesele = ()=>{
        setConfirmNoDzieciWesele(true)
        setConfirmYesDzieciWesele(false)
    }
    const confirmTakDzieciPoprawiny = ()=>{
        setConfirmYesDzieciPoprawiny(true)
        setConfirmNoDzieciPoprawiny(false)
    }
    const confirmNieDzieciPoprawiny = ()=>{
        setConfirmNoDzieciPoprawiny(true)
        setConfirmYesDzieciPoprawiny(false)
    }


// wysłanie formularza

    const confirmHandler = (e) => {
        e.preventDefault()

        const tokenNumber = sessionStorage.getItem('token')

        let confirmWedding
        let confirmRevisions
        let confirmKidsWedding
        let confirmKidsRevisions
        let confirmWeddingAlone
        let confirmRevisionsAlone

        if (confirmYesWesele){
            confirmWedding = true
        }
        if (confirmNoWesele){
            confirmWedding = false
        }
        if (confirmYesPoprawiny){
            confirmRevisions = true
        }
        if (confirmNoPoprawiny){
            confirmRevisions = false
        }
        if (confirmYesDzieciWesele){
            confirmKidsWedding = true
        }
        if (confirmNoDzieciWesele){
            confirmKidsWedding = false
        }
        if (confirmYesDzieciPoprawiny){
            confirmKidsRevisions = true
        }
        if (confirmNoDzieciPoprawiny){
            confirmKidsRevisions = false
        }
        if (confirmYesWeseleAlone){
            confirmWeddingAlone = true
            confirmWedding = null
        }
        if (!confirmYesWeseleAlone){
            confirmWeddingAlone= false
        }
        if (confirmYesPoprawinyAlone){
            confirmRevisionsAlone = true
            confirmRevisions = null
        }
        if (!confirmYesPoprawinyAlone){
            confirmRevisionsAlone= false
        }

        const data = {
            "confirmWedding": confirmWedding,
            "confirmRevisions": confirmRevisions,
            "confirmKidsWedding": confirmKidsWedding,
            "confirmKidsRevisions": confirmKidsRevisions,
            "confirmWeddingAlone": confirmWeddingAlone,
            "confirmRevisionsAlone": confirmRevisionsAlone
        }

        fetch('https://weddingbackend.azurewebsites.net/Account/confirm',{
			method: 'PUT',
			body: JSON.stringify(data),
			headers: {
            'Authorization': `Bearer ${tokenNumber}`,
            'Content-Type': 'application/json'
			},
		  })
          .then((res)=>{
            if (res.ok){
                console.log('sukces')
                setConfirmInfo(true)
                setConfirmInfoError(false)
            }
            else{
                console.log('błąd')
                setConfirmInfo(false)
                setConfirmInfoError(true)
            }
          })
          
          .catch(()=>{
            setConfirmInfoError(true)
            console.log('błąd')})
    }
        
    return(
        <div className="containerLogin">
            <span className="toClose" onClick={closeWindowReaction}></span>
            {!confirmForm && (
                <form className="loginForm">
                <h2>Logowanie</h2>
                <label> Login: </label>
                <input type="text" value={login} onChange={(e)=> setLogin(e.target.value)} required/>
                <label> Hasło:</label>
                <input type="password" value = {password} onChange={(e)=> setPassword(e.target.value)} required/>
                <button type="submit" onClick={loginHandler}>Zaloguj</button>
                {errorLogin && <span className="error">Błędne dane logowania!</span>}
            </form>
            )}
            {confirmForm && (
                <form className="confirmForm">
                <div className="confirmWesele">
                    <h2>Czy potwierdzasz obecność {!single && <span>2 osób</span>} na weselu 21.09.2024?</h2>
                        <div className="buttons">
                            <button type="button" className={confirmYesWesele ? "selected" : ""} onClick={confirmTakWesele}>TAK</button>
                            {!single && <button type="button" className={confirmYesWeseleAlone ? "selected" : ""} onClick={confirmTakWeseleAlone}>Przyjdę sam/sama</button>}
                            <button type="button" className={confirmNoWesele ? "selected": ""} onClick={confirmNieWesele}>Nie Będzie mnie</button>
                        </div>
                </div>
                <div className="confirmPoprawiny">
                    <h2>Czy potwierdzasz obecność {!single && <span>2 osób</span>} na poprawinach 22.09.2024?</h2>
                        <div className="buttons">
                            <button type="button" className={confirmYesPoprawiny ? "selected" : ""} onClick={confirmTakPoprawiny}>TAK</button>
                            {!single && <button type="button" className={confirmYesPoprawinyAlone ? "selected" : ""} onClick={confirmTakPoprawinyAlone}>Przyjdę sam/sama</button>}
                            <button type="button" className={confirmNoPoprawiny ? "selected" : ""} onClick={confirmNiePoprawiny}>Nie Będzie mnie</button>
                        </div>
                </div>
                {kids && (
                    <div className="confirmDzieciWesele">
                        <h2>Czy dzieci będą obecne na weselu?</h2>
                            <div className="buttons">
                                <button type="button" className={confirmYesDzieciWesele ? "selected": ""} onClick={confirmTakDzieciWesele}>TAK</button>
                                <button type="button" className={confirmNoDzieciWesele ? "selected" : ""} onClick={confirmNiekDzieciWesele}> NIE</button>
                            </div>
                    </div>
                )}
                {kids && (
                    <div className="confirmDzieciPoprawiny">
                        <h2>Czy dzieci będą obecne na poprawinach?</h2>
                            <div className="buttons">
                                <button type="button" className={confirmYesDzieciPoprawiny ? "selected" : ""} onClick={confirmTakDzieciPoprawiny}>TAK</button>
                                <button type="button" className={confirmNoDzieciPoprawiny ? "selected" : ""} onClick={confirmNieDzieciPoprawiny}> NIE</button>
                            </div>
                    </div>
                )}
                <button  className="buttonAccept" type="submit" onClick={confirmHandler}>Zapisz</button>
                {confirmInfo && (<div className="confirmInfo"><p >Dziękujemy za informacje!</p>
                    {confirmNoPoprawiny && confirmNoWesele ? <p>Bardzo nam przykro :(</p> : <p>Do zobaczenia :D</p>}
                </div>)}
                {confirmInfoError && <span className="error"><p>Wystąpił problem przy próbie przesłania odpowiedzi.</p> <p>Spróbuj ponownie lub skontaktuj się z nami telefonicznie (+48 723 110 459).</p></span>}
                
            </form>
            )}
            
        </div>
    )
}