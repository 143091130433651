import React from "react"

//plik css
import './whereAndWhen.css'

export default function WhereAndWhen(){
    return(
        <div className="containerWhereAndWhen">
            <div class="when-and-where-part when">
                <span class="material-symbols-outlined"> calendar_clock </span>
                <h2>KIEDY</h2>
                <p>21.09.2024</p>
                <p>16:00</p>
            </div>
             <div class="when-and-where-part where-ceremony">
                <span class="material-symbols-outlined"> church </span>
                <h2>GDZIE ŚLUB</h2>
                <p>Kościół pw. św. Piotra i Pawła we Wróblewie</p>
            </div>
            <div class="when-and-where-part where-wedding">
                <span class="material-symbols-outlined"> restaurant </span>
                <h2>GDZIE WESELE</h2>
                <p>Sala Plenerowa</p>
                <p>Dwór Kamionacz</p>
            </div>
        </div>
    )
}