import React, {useState,useEffect} from "react"

// plik css
import './header.css'

//elementy
import Login from "../../elements/login/Login"
import Time from "../../elements/time/Time"

export default function Header(){
    const[openLoginWindow, setOpenLoginWindow]=useState(false)
    let tokenNumber = sessionStorage.getItem('token')
    const [token, setToken] = useState(tokenNumber)
    
    useEffect(() => {
    }, [openLoginWindow])

    useEffect((()=>checkToken),[token])
  
    const loginHandler=()=>{
        setOpenLoginWindow(true)
    }
    
    const closeWindowHandler= () =>{
        setOpenLoginWindow(false)
        checkToken()
    }

    const logoutHandler = (e) => {
        e.preventDefault()
        setToken(sessionStorage.removeItem('token'))
    }
    
    const checkToken =()=>{
        setToken(sessionStorage.getItem('token'))
    }
            
        
        
    return(
        <div className="containerHeader">
            {token && <button className="logout" onClick={logoutHandler}>WYLOGUJ</button>}
            <p>Aleksandra & Jakub</p>
            <button onClick={loginHandler}>POTWIERDŹ OBECNOŚĆ</button>
            {openLoginWindow && <Login onEvent={closeWindowHandler} />}
            <span className="time"><Time/></span>
        </div>
    ) 
}