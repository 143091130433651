import React, {useState, useEffect} from 'react'

// plik css
import './time.css'

export default function Time (){
    const calculateTimeLeft = () => {
        const targetDate = new Date('September 21, 2024 16:00:00')
        const now = new Date()
        const difference = targetDate - now;
        let timeLeft = {}

        if (difference > 0) {
            timeLeft = {
                dni: Math.floor(difference / (1000 * 60 * 60 * 24)),
                h: Math.floor((difference / (1000 * 60 * 60)) % 24),
                min: Math.floor((difference / 1000 / 60) % 60),
                s: Math.floor((difference / 1000) % 60)
            }
        }
        return timeLeft
    }
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft())
        }, 1000)

        return () => clearInterval(timer)
    }, [])

    const timerComponents = []

    Object.keys(timeLeft).forEach(interval => {
        if (!timeLeft[interval]) {
            return
        }

        timerComponents.push(
            <span key={interval}>
                {timeLeft[interval]} {interval}{" "}
            </span>
        )
    })

    return (
        <div className='timeContainer'>
            <p>Do ślubu pozostało:</p>
            {timerComponents.length ? timerComponents : <span>Dobrej zabawy!</span>}
        </div>
    )
    
}
